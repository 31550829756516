<template>
  <v-container fluid>
    <v-card>
      <v-app-bar
        absolute
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-spacer></v-spacer>
        <v-list-item-action class="mr-1">
          <v-btn
            icon
            outlined
            title="Создать"
            :class="{ 'primary--text': isDraw }"
            @click="onCreate"
            :disabled="editObj"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>

        <v-list-item-action class="mr-1 ml-0">
          <v-btn
            icon
            outlined
            title="Редактировать"
            :class="{ 'primary--text': editObj }"
            @click="onEdit"
            :disabled="isDraw"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-app-bar>

      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-x-hidden"
        max-height="90vh"
      >
        <v-row style="height: 60px"></v-row>
        <v-row dense>
          <v-col align="center" justify="center">
            <vl-map
              data-projection="EPSG:4326"
              style="width: 100%; height: 100vh;"
              ref="map"
            >
              <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

              <vl-layer-tile
                v-for="(item, index) in $store.state.settings.maps"
                :z-index="index"
                :opacity="item.alpha / 100"
                :key="item.id"
              >
                <vl-source-osm
                  v-if="(item.id === 'osm') & item.visible"
                ></vl-source-osm>
                <vl-source-xyz
                  v-if="(item.id === '2gis') & item.visible"
                  url="http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd"
                ></vl-source-xyz>
                <vl-source-bingmaps
                  v-if="(item.id === 'bingMapsAerial') & item.visible"
                  :api-key="$store.state.settings.apiKeyBing"
                  imagery-set="Aerial"
                ></vl-source-bingmaps>
                <vl-source-bingmaps
                  v-if="(item.id === 'bingMapsAerialWithLabels') & item.visible"
                  :api-key="$store.state.settings.apiKeyBing"
                  imagery-set="Aerial"
                ></vl-source-bingmaps>
                <vl-source-xyz
                  v-if="(item.id === 'mapbox') & item.visible"
                  url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGFnb3QiLCJhIjoiY2lveTNjbnRnMDBjOXZtbTJqNGNsNmk1NCJ9.bvTUlzXKG4A2ZejsF1v2Hw"
                ></vl-source-xyz>
                <vl-source-xyz
                  v-if="(item.id === 'arcgis') & item.visible"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  :tile-load-function="arcgisLoad"
                ></vl-source-xyz>
              </vl-layer-tile>

              <vl-layer-vector :z-index="100">
                <vl-source-vector :loader="getDistrictsData" />
                <vl-style-func :factory="districtStyle" />
              </vl-layer-vector>

              <vl-layer-vector :z-index="101" id="clusterLayer">
                <vl-source-vector :loader="getClusterGrid" />
                <vl-style-func :factory="styleFunction" />
              </vl-layer-vector>
              <vl-layer-vector :z-index="101" id="clusterDraw">
                <vl-source-vector
                  :features.sync="drawFeatures"
                  ident="featuresDraw"
                ></vl-source-vector>
              </vl-layer-vector>
              <vl-interaction-draw
                v-if="isDraw"
                type="Polygon"
                source="featuresDraw"
                @drawend="drawEnd"
                stop-click
              />
              <vl-interaction-select
                :features.sync="selectedFeatures"
                :layers="['clusterLayer', 'clusterDraw']"
                @select="onSelect"
                @unselect="onUnselect"
                ident="featuresSelected"
                ref="select"
              />
              <vl-interaction-modify
                source="featuresSelected"
                @modifyend="onModifyEnd"
              />
            </vl-map>
          </v-col>
          <ClusterGridPassport
            v-show="passport"
            :properties="properties"
            :setClusterGrid="setClusterGrid"
            @remove-cluster="removeClusterGrid"
            :isDraw="isDraw"
          />
        </v-row>
      </v-sheet>
      <v-card>
        <v-snackbar v-model="snackbar" :timeout="timeout" top right outlined>
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";
import { mapActions } from "vuex";
import { Style, Stroke, Fill, Text } from "ol/style";
import ClusterGridPassport from "../passports/ClusterGridPassport.vue";
import TileState from "ol/TileState";

export default {
  name: "ClusterGridTab",

  data() {
    return {
      zoom: 13,
      center: [76.889709, 43.238949],
      features: [],
      drawFeatures: [],
      selectedFeatures: [],
      formatWKT: new WKT(),
      formatGeoJSON: new GeoJSON(),
      passport: false,
      properties: {},
      isDraw: false,
      editObj: false,
      snackbar: false,
      text: "",
      timeout: 2000,
      geometryModify: null
    };
  },

  methods: {
    ...mapActions([
      "getDistrictsData",
      "getClusterGrid",
      "createClusterGrid",
      "pushClusterGrid",
      "destroyClusterGrid"
    ]),

    arcgisLoad(imageTile, src) {
      let map = this.$refs.map;
      let zoom = map.getView().getZoom();
      if (zoom > 19) {
        imageTile.setState(TileState.ERROR);
      } else {
        imageTile.getImage().src = src;
      }
    },
    districtStyle() {
      return () => {
        let baseStyle = new Style({
          fill: new Fill({
            color: "rgba(255,255,255, 0.5)"
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 3
          })
        });
        return [baseStyle];
      };
    },
    styleFunction() {
      return feature => {
        let baseStyle = new Style({
          fill: new Fill({
            color: String(feature.get("color"))
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 1
          }),
          text: new Text({
            text: feature.get("name") ? String(feature.get("name")) : "",
            scale: 1.5
          })
        });
        return [baseStyle];
      };
    },
    drawEnd() {
      this.selectedFeatures = [];
      this.selectedFeatures.push(
        this.drawFeatures[this.drawFeatures.length - 1]
      );
    },
    onSelect(feature) {
      this.passport = true;
      this.properties = Object.assign({}, feature.json.properties);
    },
    onUnselect() {
      this.passport = false;
    },
    async setClusterGrid({ name, color }) {
      let feature = this.formatGeoJSON.readFeature(this.selectedFeatures[0]);
      let wktRepresenation = this.formatWKT.writeFeature(feature);
      let properties = this.selectedFeatures[0].properties;
      if (properties) {
        if (this.geometryModify !== null)
          wktRepresenation = this.geometryModify;
        await this.pushClusterGrid({
          id: properties.id,
          geometry: wktRepresenation,
          name: name,
          color: color
        });
        this.geometryModify = null;
      } else {
        await this.createClusterGrid({
          geometry: wktRepresenation,
          name: name,
          color: color.hexa
        });
      }
      setTimeout(() => {
        this.drawFeatures = [];
        this.selectedFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.$refs.map.refresh();
      }, 1000);
    },

    async removeClusterGrid() {
      let properties = this.selectedFeatures[0].properties;
      if (properties) await this.destroyClusterGrid({ id: properties.id });
      setTimeout(() => {
        this.drawFeatures = [];
        this.selectedFeatures = [];
        this.passport = false;
        this.text = this.$store.state.message.msg;
        this.snackbar = true;
        this.$refs.map.refresh();
      }, 1000);
    },
    onCreate() {
      this.isDraw = !this.isDraw;
      if (!this.isDraw) {
        this.drawFeatures = [];
        this.selectedFeatures = [];
        this.passport = false;
      }
    },
    onEdit() {
      this.editObj = !this.editObj;
      if (this.editObj) {
        this.$refs.select.setActive(true);
      } else {
        this.$refs.select.setActive(false);
        this.selectedFeatures = [];
      }
    },
    onModifyEnd(evt) {
      let feature = this.formatGeoJSON.readFeature(evt.json[0].geometry);
      this.geometryModify = this.formatWKT.writeFeature(feature);
    }
  },

  async mounted() {
    this.$refs.select.setActive(false);
  },

  components: { ClusterGridPassport }
};
</script>

<style scoped>
::v-deep .v-list-item__action {
  margin: 0;
}
</style>
