<template>
  <v-card width="350" height="100vh">
    <v-form
      @submit.prevent="
        setClusterGrid({
          name: clusterGridName,
          color: clusterGridColor
        })
      "
    >
      <v-col align="center">
        <h3 class="mt-5 mb-2">Паспорт микрорайона</h3>
        <v-row dense>
          <v-card-text class="text-start">Название микрорайона</v-card-text>
          <v-text-field
            type="text"
            v-model="clusterGridName"
            outlined
            hide-details
          ></v-text-field>
          <v-card-text class="text-start">Цвет микрорайона</v-card-text>
          <v-color-picker
            v-model="clusterGridColor"
            class="ma-2"
            hide-canvas
            hide-mode-switch
          ></v-color-picker>
        </v-row>
        <div class="mt-2">
          <v-row dense justify="center">
            <v-col cols="12" sm="6">
              <v-card-text class="text-center">
                <v-btn type="submit" class="font-weight-bold">Сохранить</v-btn>
              </v-card-text>
            </v-col>
            <v-col v-if="!isDraw" cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  v-on:click="$emit('remove-cluster')"
                  >Удалить</v-btn
                ></v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "ClusterGridPassport",

  props: { properties: Object, setClusterGrid: Function, isDraw: Boolean },

  data() {
    return {
      clusterGridName: null,
      clusterGridColor: null
    };
  },

  watch: {
    properties: function(newValue) {
      this.clusterGridName = newValue.name;
      this.clusterGridColor = newValue.color;
    }
  }
};
</script>
